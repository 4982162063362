import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as yup from "yup";
import { getLotsNumber, investAmount } from "../actions/investActions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { showToast } from "../../../utils/toast";
import { useTranslation } from "react-i18next";
import Selector from "../../Common/selector";
import Input from "../../Common/Input";

const schema = yup.object({
  network: yup.string().required("Network is a required field"),
  amount: yup
    .number()
    .required("Amount is a required field")
    .oneOf([5000, 25000, 50000,100000], "Invalid amount selected"),
  hash: yup.string().required("Transaction hash is required"),
});

const InvestWithWallet = ({ setError }) => {
  //Disclaimer states
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const contentRef = useRef(null);

  // disclaimer events
  const handleCheckClick = (event) => {
    event.preventDefault();
    setShowDisclaimer(true);
  };
  const handleAccept = () => {
    setAccepted(true);
    setShowDisclaimer(false);
  };

  const handleReject = () => {
    setAccepted(false);
    setShowDisclaimer(false);
  };

  const handleClose = () => {
    setShowDisclaimer(false);
  };

  const handleScroll = () => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const scrolledToBottom =
        contentElement.scrollHeight - contentElement.scrollTop ===
        contentElement.clientHeight;
      setIsScrolledToBottom(scrolledToBottom);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  // network info
  const options = [
    { label: "Singapore", value: "Singapore" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Thailand", value: "Thailand" },
  ];
  const option = [
    { label: "BSC", value: "0x17d091ade593c7404f558ffB21102Fa96e357164" },
    { label: "TRC", value: "TW1JNHYVBpw8hhSWVkasTMZfUoegTthD3T" },
    { label: "ERC", value: "0x238B40131C9760CDbCa61505C65429Ef62D7eBA3" },
  ];

  // lots
  useEffect(() => {
    dispatch(getLotsNumber());
  }, []);

  const { lots_details } = useSelector((state) => state.history);
  const { loading } = useSelector((state) => state.loading);
  const [selectedImage, setSelectedImage] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  // form submit
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    values: { amount: 0, country: "" },
    resolver: yupResolver(schema),
  });
  const { country, amount, network } = watch();

  const _invest = (data) => {
    if (amount % 3 === 0) {
      setError(null);
      if (selectedImage) {
        dispatch(investAmount(data, selectedImage, history));
      } else {
        showToast("error", "Please Update Image");
      }
    } else {
      showToast("error", "Amount should be multiple of 5000.");
    }
  };

  // lang
  const { t, i18n } = useTranslation();

  return (
    <form onSubmit={handleSubmit((data) => _invest(data))}>
      <div className="d-flex flex-wrap mt-2">
        <p className="mr-3">{t("Deposit Wallet Address")}:</p>
        <p className="wallet-address">
          <p>{network ? network : "Select Network first"}</p>
        </p>
      </div>
      <Selector
        label="Network:"
        options={option}
        errors={errors?.network?.message}
        register={register}
        defaultOption={"Select Network"}
        value={network}
        name="network"
      />

      <div className="d-flex amount-dropdown mb-2">
        <label>Amount USDT:</label>
        <select {...register("amount")} className={errors.amount ? 'error' : ''}>
          <option value="Select Amount" disabled selected>Select Amount</option>
          <option value="5000">5000</option>
          <option value="25000">25000</option>
          <option value="50000">50000</option>
          <option value="100000">100000</option>
        </select>
        {errors.amount && <p className="error-message">{errors.amount.message}</p>}
      </div>
      <div className="flex-column">
        <Input
          label="Trx Hash:"
          errors={errors?.hash?.message}
          type="text"
          name="hash"
          register={register}
          className={true}
        />
      </div>
      <p>{t("Label33")}</p>
      <div className="d-flex flex-wrap align-items-center">
        <label className="mr-3 mt-2">{t("Payment Proof")}:</label>
        <div className="upload-btn-wrapper">
          <div>
            <h1>
              <button className="upload-btn">{t("Upload a file")}</button>
            </h1>
            {selectedImage && (
              <div>
                <img
                  alt="not fount"
                  width={"250px"}
                  src={URL.createObjectURL(selectedImage)}
                />
                <br />
                <br />
                <br />
              </div>
            )}
            <input
              type="file"
              name="myImage"
              onChange={(event) => {
                setSelectedImage(null);
                setSelectedImage(event.target.files[0]);
              }}
            />
          </div>
        </div>
      </div>
      <div className="terms-section gap-3">
        <label className="mt-0 mb-3">
          <input
            type="checkbox"
            required
            checked={accepted}
            onChange={handleCheckClick}
          />
        </label>
        <p>
          {t(
            "I agree to the terms and conditions stated in the Maconha Holding Investment Disclaimer"
          )}
        </p>

        {showDisclaimer && (
          <div className="popup-container">
            <div className="popup w-75">
              <div className="d-flex mb-3 justify-content-between">
                <h2>Maconha Holding Investment Disclaimer</h2>
                <button className="close-button" onClick={handleClose}>
                  &times;
                </button>
              </div>
              <div
                className="popup-content"
                ref={contentRef}
                onScroll={handleScroll}
              >
                <div className="scrollable-content">
                  <strong>Disclaimer Agreement</strong>
                  <p>
                    This Investment Disclaimer ("Agreement") is made and entered
                    into by and between you ("The Investor") and Maconha Holding
                    ("The Company"). By checking the "I agree" box, Investor
                    hereby acknowledges and agrees to the terms and conditions
                    set forth in this Agreement. The following provisions
                    outline the nature of the investment program and related
                    considerations. This Agreement is legally binding and serves
                    to clarify the responsibilities and rights of both parties.
                  </p>
                  <ul>
                    <li>
                      <strong>1. Investment Nature and Risks</strong>
                      <ul>
                        <li>
                          Investor acknowledges that investing in the cannabis
                          sector carries inherent risks, including but not
                          limited to financial losses, market volatility, and
                          regulatory uncertainties.
                        </li>
                        <li>
                          The company does not provide any guarantees or
                          warranties regarding the profitability, performance,
                          or outcome of the Investor's investment. Investors
                          shall bear all risks associated with their investment
                          decision.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>2. Return on Investment (ROI)</strong>
                      <ul>
                        <li>
                          Investor understands that the Company offers a
                          4-season (20 months) investment program with various
                          ROI options
                        </li>
                        <li>
                          Investor acknowledges that the Company's
                          representations regarding ROI are estimates and
                          projections, subject to the performance of cultivation
                          activities and market dynamics.
                        </li>
                        <li>
                          The investor acknowledges and agrees that the
                          investment period is fixed at 20 months.
                        </li>
                        <li>
                          Investor further acknowledges that they cannot
                          withdraw their principal investment until the
                          completion of the 20-month period.
                        </li>
                        <li>
                          Dividends, however, can be withdrawn on the 5th, 10th,
                          15th, and 20th months.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>3. Referral Programme</strong>
                      <ul>
                        <li>
                          Investor recognizes and accepts the innovative
                          structure of the referral programme.
                        </li>
                        <li>
                          Investor acknowledges that they can earn 15% of the
                          investment amount from direct referrals and 5% from
                          the investment amount of indirect referrals
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>4. Monthly Campaign</strong>
                      <ul>
                        <li>
                          The Company introduces a special Monthly Campaign
                          offer available for a limited period of 3 months.
                        </li>
                        <li>
                          The Monthly Campaign is designed to incentivize and
                          reward investors during the initial phase of the
                          program
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>5. Legal Compliance</strong>
                      <ul>
                        <li>
                          The Investor understands that their investment is
                          subject to relevant laws and regulations, both
                          domestically and internationally.
                        </li>
                        <li>
                          The company operates under valid licenses, adhering to
                          legal requirements for cannabis product sales.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>
                        6. Personal Responsibility and Acknowledgment
                      </strong>
                      <ul>
                        <li>
                          The Investor acknowledges and confirms that their
                          investment decision is based on a thorough assessment
                          and understanding of the risks.
                        </li>
                        <li>
                          The Investor acknowledges that Company is not
                          responsible for any financial losses or adverse
                          outcomes arising from their investment
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>7. Dispute Resolution</strong>
                    </li>
                  </ul>
                  <p>
                    In the event of any disputes arising under this Agreement,
                    both Investor and Company agree to resolve such disputes
                    through binding arbitration in accordance with the laws of
                    the jurisdiction where the Company is registered.
                  </p>
                  <ul>
                    <li>
                      <strong>8. Entire Agreement</strong>
                    </li>
                  </ul>
                  <p>
                    This Agreement constitutes the entire agreement between
                    Investor and Company regarding the investment program,
                    superseding any prior agreements or understandings, whether
                    oral or written.
                  </p>
                  <p>
                    By checking the "I agree" box, The Investor affirms that
                    they have read, understood, and agreed to the terms and
                    conditions set forth in this Agreement. If The Investor does
                    not agree to the terms stated herein, they must not proceed
                    with their investment.
                  </p>
                  <p>
                    This Agreement is executed as of the date of the Investor's
                    agreement.
                  </p>
                </div>
              </div>
              <div className="popup-buttons">
                <button
                  className="btn btn-danger mr-3"
                  onClick={handleReject}
                  disabled={!accepted}
                >
                  Reject
                </button>
                <button
                  className="btn btn-success"
                  onClick={handleAccept}
                  disabled={!isScrolledToBottom}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {!loading ? (
        <button type="submit" className="w-100">
          {t("Submit")}
        </button>
      ) : (
        <button type="button" className="w-100">
          <div className="loader"></div>
        </button>
      )}
    </form>
  );
};

export default InvestWithWallet;
