import React, { useEffect, useState } from "react";
import Level from "./Level";
import { showToast } from "../../utils/toast";

function activeFalse(e, i = -1) {
  if (e === null) {
  } else {
    return Array.from({ length: e.length }, (_, j) => i === j);
  }
}

const LevelUsers = ({ data, level, parent }) => {
  const [activeLevel, setActiveLevel] = useState(
    activeFalse(data[`level${level}`])
  );

  const [addedLevel, setAddedLevel] = useState([]);

  const [levelData, setLevelData] = useState([]);

  useEffect(() => {
    if (data[`level${level}`] === null) {
      showToast("error", "No further refered Users");
    } else {
      if (parent) {
        updateLevelData();
      } else {
        setLevelData(data[`level${level}`]);
      }
      setActiveLevel(activeFalse(data[`level${level}`]));
    }
  }, [parent]);

  const updateLevelData = () => {
    if (data[`level${level}`] !== null) {
      const newData = data[`level${level}`].filter(
        (user) => user.parent === parent.user
      );
      setLevelData(newData);
    }
  };

  const addLevel = (user) => {
    let nextLevel = [];
    nextLevel.push(
      <LevelUsers
        data={data}
        id={`${level + 1}-data`}
        level={level + 1}
        parent={user}
      />
    );
    setAddedLevel(nextLevel);
  };

  const onClick = (i, user) => {
    const nextLevelData = data[`level${level + 1}`];
    let hasChild = true;
    if (nextLevelData !== null) {
      const childs = nextLevelData?.filter(
        (nextUser) => nextUser.parent === user.user
      );
      if (childs?.length < 1) {
        hasChild = false;
      }
    } else {
      hasChild = false;
    }

    if (hasChild) {
      setActiveLevel(activeFalse(levelData, i));
      addLevel(user);
      const hideSiblingsElement = document.getElementById(`${level + 1}-data`);
      hideNextLevels(hideSiblingsElement);
    } else {
      showToast("error", "No further refered Users");
    }
  };

  const hideNextLevels = (element) => {
    if (element !== null) {
      let next = element.nextSibling;
      while (next !== null) {
        next.style.visibility = "hidden";
        next = next.nextSibling;
      }
    }
  };

  return (
    <>
      {levelData.length > 0 && (
        <>
          <Level
            levelData={levelData}
            id={`${level}-data`}
            active={activeLevel}
            onClick={onClick}
          />
          {addedLevel.length > 0 && (
            <>
              {addedLevel.map((level) => (
                <>{level}</>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};
export default LevelUsers;
